<template>
  <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
  <UiModalContainer
    :prevent-out-side-close="true"
    :modal-show="showModal"
    :modal-width="60"
    :modal-padding-top="true"
    footer-classes="flex justify-end gap-3"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <div>
        <span v-if="edit" v-i18n="dashboard">Edit</span>
        <span v-else v-i18n="dashboard">Add</span>
        <span v-if="isSalaryType">&nbsp;Salary</span>
        <span v-else>&nbsp;Salary Template</span>
      </div>
    </template>
    <template v-slot>
      <div class="py-6 px-6">
        <div class="flex flex-wrap">
          <div class="md:w-2/4 px-1">
            <UiSingleSelect
              v-model="salaryTemplate.staff_user_id"
              title="STAFF"
              label="full_name"
              :options="staffList"
              :class="$style.widthItems"
              class="flex-1"
              reduce="id"
              rules="required"
              input-color="bg-white"
            />
          </div>
          <div class="md:w-2/4 px-1">
            <InputBox
              v-model="salaryTemplate.base_salary"
              type="text"
              name="Base Salary"
              title="Base Salary"
              label="Base Salary"
              placeholder="Write"
              :class="$style.widthItems"
              rules="integer|required"
              class="flex-1 w-full"
            />
          </div>
          <div v-if="isSalaryType" class="md:w-full px-1">
            <InputBox
              v-model="salaryTemplate.title"
              type="text"
              name="salary_title"
              title="Salary Title"
              label="Salary Title"
              placeholder="Salary Title"
              :class="$style.widthItems"
              class="flex-1 w-full"
              rules="required"
            />
          </div>
          <div
            class="flex w-full border text-center border-primary-grey mt-2 mb-5 cursor-pointer rounded-md"
          >
            <div
              class="flex-1 border-r py-2"
              :class="selectedType === 'salaries_allowances' ? 'bg-primary-green text-white' : ''"
              @click="setSelectedType('salaries_allowances')"
            >
              Salary allowance
            </div>
            <div
              class="flex-1 py-2 border-r"
              :class="selectedType === 'salaries_deductions' ? 'bg-primary-green text-white' : ''"
              @click="setSelectedType('salaries_deductions')"
            >
              Salary Deduction
            </div>
            <div
              v-if="isSalaryType"
              class="flex-1 py-2 border-r"
              :class="selectedType === 'salaries_bonuses' ? 'bg-primary-green text-white' : ''"
              @click="setSelectedType('salaries_bonuses')"
            >
              Salary Bonus
            </div>
            <div
              class="flex-1 py-2"
              :class="selectedType === 'extra_working_unit' ? 'bg-primary-green text-white' : ''"
              @click="setSelectedType('extra_working_unit')"
            >
              Extra Working Unit
            </div>
          </div>
          <div v-if="selectedType === 'salaries_allowances'" class="flex flex-wrap w-full">
            <div class="md:w-2/4 px-1">
              <UiSingleSelect
                :key="`ForcerenderAllowance${forceRenderAllowence}`"
                v-model="allowanceObject.allowance_type"
                title="allowance Type"
                label="title"
                :options="allowanceList"
                :class="$style.widthItems"
                class="flex-1"
                input-color="bg-white"
              />
            </div>
            <div class="md:w-2/4 px-1">
              <InputBox
                v-model.number="allowanceObject.amount"
                type="text"
                name="allowance"
                title="allowance Amount"
                label="allowance Amount"
                placeholder="allowance Amount"
                :class="$style.widthItems"
                rules="integer"
                class="flex-1 w-full"
              />
            </div>
            <div class="w-full">
              <button
                class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button ml-auto"
                @click="addAllowanceObject"
              >
                <span>Add</span>
              </button>
            </div>
            <div
              v-if="salaryTemplate.salaries_allowances && salaryTemplate.salaries_allowances.length"
              class="w-full"
            >
              <p class="text-base text-primary-grey-strongs text-center font-bold my-2">
                Allowance
              </p>
              <p
                v-for="(allowance, index) in salaryTemplate.salaries_allowances"
                :key="allowance.id"
                class="text-base flex"
              >
                <span>Allowance type:</span>
                <span class="text-primary-green px-1">
                  {{
                    allowance.title || (allowance.allowance_type && allowance.allowance_type.title)
                  }}
                </span>
                <span class="px-1">{{ '&' }}&nbsp;Amount:</span>
                <span class="px-1 text-primary-green">{{ allowance.amount }}</span>
                <span
                  class="text-sm ml-auto text-red-700 pr-2 cursor-pointer"
                  @click="removeAllowanceObject(allowance, index)"
                >
                  remove
                </span>
              </p>
            </div>
          </div>
          <div v-if="selectedType === 'salaries_deductions'" class="flex flex-wrap w-full">
            <div class="md:w-2/4 px-1">
              <UiSingleSelect
                :key="`forceRenderDeduction${forceRenderDeduction}`"
                v-model="deductionObject.deduction_type"
                title="Deduction Type"
                label="title"
                :options="deductionList"
                :class="$style.widthItems"
                class="flex-1"
                input-color="bg-white"
              />
            </div>
            <div class="md:w-2/4 px-1">
              <InputBox
                v-model.number="deductionObject.amount"
                type="text"
                name="Deduction_Amount"
                title="Deduction Amount"
                label="Deduction Amount"
                placeholder="Deduction Amount"
                :class="$style.widthItems"
                rules="integer"
                class="flex-1 w-full"
              />
            </div>
            <div class="w-full">
              <button
                class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button ml-auto"
                @click="addDeductionObject"
              >
                <span>Add</span>
              </button>
            </div>
            <div
              v-if="salaryTemplate.salaries_deductions && salaryTemplate.salaries_deductions.length"
              class="w-full"
            >
              <p class="text-base text-primary-grey-strongs text-center font-bold my-2">
                Deductions
              </p>
              <p
                v-for="(deduction, index) in salaryTemplate.salaries_deductions"
                :key="deduction.id"
                class="text-base flex"
              >
                <span>Deduction type:</span>
                <span class="text-primary-green px-1">
                  {{
                    deduction.title || (deduction.deduction_type && deduction.deduction_type.title)
                  }}
                </span>
                <span class="px-1">{{ '&' }}&nbsp;Amount:</span>
                <span class="px-1 text-primary-green">{{ deduction.amount }}</span>
                <span
                  class="text-sm ml-auto text-red-700 pr-2 cursor-pointer"
                  @click="removeDeductionObject(deduction, index)"
                >
                  remove
                </span>
              </p>
            </div>
          </div>
          <div
            v-if="selectedType === 'salaries_bonuses' && isSalaryType"
            class="flex flex-wrap w-full"
          >
            <div class="md:w-2/4 px-1">
              <UiSingleSelect
                :key="`forceRenderBonus${forceRenderBonuses}`"
                v-model="bonusesObject.bonus_type"
                title="Bonus Type"
                label="title"
                :options="bonusesList"
                :class="$style.widthItems"
                class="flex-1"
                input-color="bg-white"
              />
            </div>
            <div class="md:w-2/4 px-1">
              <InputBox
                v-model.number="bonusesObject.amount"
                type="text"
                name="Bonus_Amount"
                title="Bonus Amount"
                label="Bonus Amount"
                placeholder="Bonus Amount"
                :class="$style.widthItems"
                rules="integer"
                class="flex-1 w-full"
              />
            </div>
            <div class="w-full">
              <button
                class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button ml-auto"
                @click="addBonusObject"
              >
                <span>Add</span>
              </button>
            </div>
            <div
              v-if="salaryTemplate.salaries_bonuses && salaryTemplate.salaries_bonuses.length"
              class="w-full"
            >
              <p class="text-base text-primary-grey-strongs text-center font-bold my-2">Bonuses</p>
              <p
                v-for="(bonus, index) in salaryTemplate.salaries_bonuses"
                :key="bonus.id"
                class="text-base flex"
              >
                <span>Bonus type:</span>
                <span class="text-primary-green px-1">
                  {{ bonus.title || (bonus.bonus_type && bonus.bonus_type.title) }}
                </span>
                <span class="px-1">{{ '&' }}&nbsp;Amount:</span>
                <span class="px-1 text-primary-green">{{ bonus.amount }}</span>
                <span
                  class="text-sm ml-auto text-red-700 pr-2 cursor-pointer"
                  @click="removeBonusObject(bonus, index)"
                >
                  remove
                </span>
              </p>
            </div>
          </div>
          <div v-if="selectedType === 'extra_working_unit'" class="flex flex-wrap w-full">
            <div class="md:w-2/4 px-1">
              <UiSingleSelect
                v-model="salaryTemplate.extra_working_unit_id"
                title="Extra Working Unit"
                label="title"
                :options="extraWorkingDayList"
                :class="$style.widthItems"
                class="flex-1"
                reduce="id"
                input-color="bg-white"
              />
            </div>
            <div class="md:w-2/4 px-1">
              <InputBox
                v-model="salaryTemplate.extra_working_unit_rate"
                type="text"
                name="Rate"
                title="Rate"
                label="Rate"
                placeholder="Rate"
                :class="$style.widthItems"
                rules="integer"
                class="flex-1 w-full"
              />
            </div>
            <div v-if="isSalaryType" class="w-full px-1">
              <InputBox
                v-model="salaryTemplate.extra_working_quantity"
                type="text"
                name="Quantity"
                title="Quantity"
                label="Quantity"
                placeholder="Quantity"
                :class="$style.widthItems"
                rules="integer"
                class="flex-1 w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
        v-i18n="dashboard"
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
        :disabled="isLoading"
        @click="handleClick('cancel')"
      >
        Cancel
      </button>
      <button
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
        @click="handleSubmit(saveSalaryTemplate(invalid))"
      >
        <span v-if="isLoading">
          <Loader />
        </span>
        <span v-else>
          <span v-if="edit" v-i18n="dashboard">Update</span>
          <span v-else v-i18n="dashboard">Save</span>
        </span>
      </button>
    </template>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { mapActions, mapState } from 'vuex'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { objectDeepCopy } from '@utils/generalUtil'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'

export default {
  components: {
    Loader,
    InputBox,
    UiModalContainer,
    // ValidationObserver,
    UiSingleSelect,
  },
  props: {
    edit: { type: Object, default: null },
    isSalaryType: { type: Boolean, default: false },
  },
  emits: ['update'],
  data() {
    return {
      dashboard: 'dashboard',
      forceRenderDeduction: 0,
      forceRenderAllowence: 0,
      forceRenderBonuses: 0,
      title: 'title',
      salaryTemplate: {},
      salaryGeneratorId: this.$route.params.id,
      allowanceObject: {},
      deductionObject: {},
      bonusesObject: {},
      staffList: [],
      selectedType: '',
      allowanceList: [],
      deductionList: [],
      extraWorkingDayList: [],
      bonusesList: [],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.salary.isLoading,
      showModal: (state) => state.salary.showModal,
    }),
  },
  created() {
    this._getStaffData()
  },
  mounted() {
    if (this.edit) {
      this.salaryTemplate = objectDeepCopy(this.edit)
      if (this.salaryTemplate.extra_working_unit && this.salaryTemplate.extra_working_unit_rate) {
        this.salaryTemplate.extra_working_unit_id = this.salaryTemplate.extra_working_unit.id
      }
    } else {
      this.resetData()
    }
  },

  methods: {
    resetData() {
      this.salaryTemplate = {
        salary_title: '',
        base_salary: '',
        salary_generator_id: this.salaryGeneratorId,
        staff_user_id: '',
        extra_working_unit_id: '',
        extra_working_unit_rate: '',
        salaries_allowances: [],
        salaries_deductions: [],
        salaries_bonuses: [],
      }
      this.allowanceObject = {
        allowance_type: '',
        amount: '',
      }
      this.deductionObject = {
        deduction_type: '',
        amount: '',
      }
      this.bonusesObject = { bonus_type_id: '', amount: '' }
    },

    addAllowanceObject() {
      if (!this.allowanceObject.allowance_type && !this.allowanceObject.allowance_type.id) {
        this.createErrorToast('Please select deduction deductiont')
        return
      }
      if (!this.allowanceObject.amount) {
        this.createErrorToast('Please add allowance amount first')
        return
      }
      let allowanceObj = {
        allowance_type_id: this.allowanceObject.allowance_type.id,
        title: this.allowanceObject.allowance_type.title,
        amount: this.allowanceObject.amount,
      }
      let alreadyExsists = this.salaryTemplate?.salaries_allowances.some((val) => {
        return val.allowance_type_id === allowanceObj.allowance_type_id
      })
      if (alreadyExsists) {
        this.createErrorToast('This Allowance already exsists')
        return
      }

      this.salaryTemplate?.salaries_allowances?.push(allowanceObj)
      this.allowanceObject = {}
      this.forceRenderAllowence++
    },

    removeAllowanceObject(deduction, index) {
      this.salaryTemplate.salaries_allowances.splice(index, 1)
    },

    addDeductionObject() {
      if (!this.deductionObject.deduction_type && !this.deductionObject.deduction_type.id) {
        this.createErrorToast('Please select deduction type first')
        return
      }
      if (!this.deductionObject.amount) {
        this.createErrorToast('Please add deduction amount first')
        return
      }
      let deductionObj = {
        deduction_type_id: this.deductionObject.deduction_type.id,
        title: this.deductionObject.deduction_type.title,
        amount: this.deductionObject.amount,
      }
      let alreadyExsists = this.salaryTemplate?.salaries_deductions.some((val) => {
        return val.deduction_type_id === deductionObj.deduction_type_id
      })
      if (alreadyExsists) {
        this.createErrorToast('This Deduction already exsists')
        return
      }
      this.salaryTemplate?.salaries_deductions?.push(deductionObj)
      this.deductionObject = {}
      this.forceRenderDeduction++
    },

    removeDeductionObject(deduction, index) {
      this.salaryTemplate.salaries_deductions.splice(index, 1)
    },

    addBonusObject() {
      if (!this.bonusesObject.bonus_type && !this.bonusesObject.bonus_type.id) {
        this.createErrorToast('Please select bonus type first')
        return
      }
      if (!this.bonusesObject.amount) {
        this.createErrorToast('Please add bonus amount first')
        return
      }
      let bonusObj = {
        bonus_type_id: this.bonusesObject.bonus_type.id,
        title: this.bonusesObject.bonus_type.title,
        amount: this.bonusesObject.amount,
      }
      let alreadyExsists = this.salaryTemplate?.salaries_bonuses.some((val) => {
        return val.bonus_type_id === bonusObj.bonus_type_id
      })
      if (alreadyExsists) {
        this.createErrorToast('This Bonus already exsists')
        return
      }

      this.salaryTemplate?.salaries_bonuses?.push(bonusObj)
      this.bonusesObject = {}
      this.forceRenderBonuses++
    },

    removeBonusObject(bonus, index) {
      this.salaryTemplate.salaries_bonuses.splice(index, 1)
    },

    setSelectedType(type) {
      this.selectedType = type
      switch (type) {
        case 'salaries_allowances': {
          if (this.allowanceList && !this.allowanceList.length) this._getAllowanceTypes()
          break
        }
        case 'salaries_deductions': {
          if (this.deductionList && !this.deductionList.length) this._getDeductionTypes()
          break
        }
        case 'salaries_bonuses': {
          if (this.bonusesList && !this.bonusesList.length) this._getBonusTypes()
          break
        }
        case 'extra_working_unit': {
          if (this.extraWorkingDayList && !this.extraWorkingDayList.length)
            this._getExtraWorkingUnits()
          break
        }
      }
    },

    async saveSalaryTemplate(invalid) {
      /* INVALID */
      if (invalid) return
      let res, err
      if (this.isSalaryType) {
        this.saveSalary()
        return
      }
      if (this.edit) {
        ;[res, err] = await this.updateSalaryTemplate(this.salaryTemplate)
      } else {
        ;[res, err] = await this.createSalaryTemplate(this.salaryTemplate)
      }
      if (res) {
        this.handleClick('close')
        this.$emit('update')
      }
    },

    async saveSalary() {
      let res, err
      if (this.edit) {
        ;[res, err] = await this.updateSalary(this.salaryTemplate)
      } else {
        ;[res, err] = await this.createSalary(this.salaryTemplate)
      }
      if (res) {
        this.handleClick('close')
        this.$emit('update')
      }
    },

    createErrorToast(message) {
      this.$store.commit('toast/NEW', { type: 'error', message: message }, { root: true })
    },

    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.setModal(true)
          break
        case 'close':
          this.setModal(false)
          break
        case 'cancel':
          this.setModal(false)
          break
        case 'confirm':
          this.setModal(false)
          break
      }
    },

    async _getStaffData() {
      const [res, err] = await this.getStaffData()
      this.staffList = res.data.records.map((user) => {
        user.full_name = `${user.first_name} ${user.last_name}`
        return user
      })
    },

    async _getAllowanceTypes() {
      const [res, err] = await this.getAllowanceTypes()
      this.allowanceList = res.data.records
    },

    async _getDeductionTypes() {
      const [res, err] = await this.getDeductionTypes()
      this.deductionList = res.data.records
    },

    async _getExtraWorkingUnits() {
      const [res, err] = await this.getExtraWorkingUnits()
      this.extraWorkingDayList = res.data.records
    },

    async _getBonusTypes() {
      const [res, err] = await this.getBonusTypes()
      this.bonusesList = res.data.records
    },

    ...mapActions('salary', [
      'setModal',
      'createSalaryTemplate',
      'updateSalaryTemplate',
      'getStaffData',
      'getAllowanceTypes',
      'getDeductionTypes',
      'getExtraWorkingUnits',
      'getBonusTypes',
      'createSalary',
      'updateSalary',
    ]),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
